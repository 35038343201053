<template>
	<Spiner />
</template>

<script>
import Spiner from '../components/UI/Spinner.vue';
import quizUrl from '../store/quizUrl';
export default {
	components: {
		Spiner,
	},

	async created() {
		let postValue = {};
		postValue = {
			oauth_verifier: this.$route.query.oauth_verifier,
			oauth_token: this.$route.query.oauth_token,
		};
		try {
			if (localStorage.isAssign) {
				let response = await this.$http.put('resources/profile/asign/twitter', postValue, {
					withCredentials: true,
				});
				localStorage.removeItem('isAssign');
				if (response.status === 200) {
					alert('Twitter was bind to your account');
					this.$router.push({ name: 'account' });
				}
			} else {
				let response = await this.$http.post('auth/user_login/twitter', postValue, { withCredentials: true });
				localStorage.user_token = response.data.token;
				localStorage.social_img = response.data.img;

				this.$router.push({ name: 'advice' });
			}
		} catch (error) {
			if (error.data.message === 'User not found') {
				alert('User is not registered');
				window.location.replace(quizUrl);
			} else {
				alert('Somethign went wrong');
				this.$router.push({ name: 'login' });
			}
		} finally {
			localStorage.removeItem('isAssign');
		}
	},
};
</script>

<style>
</style>